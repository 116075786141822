<template>
  <div class="flex flex-col w-full items-start p-4">
    <div class="w-full flex justify-start items-center mb-4">
      <h2 class="text-xl font-bold">文件上传和管理</h2>
    </div>

    <div class="w-full flex justify-start border border-gray-300 rounded-md p-4 mb-4">
      <!-- 文件上传 -->
      <el-upload action="/api/upload" :on-success="handleUploadSuccess"
        :on-error="handleUploadError" :before-upload="beforeUpload" :show-file-list="false">
        <el-button type="primary">点击上传</el-button>
      </el-upload>
    </div>


    <!-- 文件列表 -->
    <div class="w-full flex justify-start border border-gray-300 rounded-md p-4">
      <el-table :data="fileList" class="w-full bg-transparent" v-loading="loading">
        <el-table-column prop="name" label="文件名"></el-table-column>
        <el-table-column prop="lastModified" label="最后修改时间"></el-table-column>
        <el-table-column prop="size" label="大小(字节)"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button size="small" @click="handleDownload(scope.row)">下载</el-button>
            <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default defineComponent({
  name: 'UploadView',
  setup() {
    const fileList = ref([])
    const loading = ref(false)
    const fetchFileList = async () => {
      loading.value = true
      try {
        const response = await axios.get('/api/files')
        fileList.value = response.data
      } catch (error) {
        ElMessage.error('获取文件列表失败')
      } finally {
        loading.value = false
      }
    }

    const handleUploadSuccess = (response: any, uploadFile: any) => {
      ElMessage.success('文件上传成功')
      fetchFileList()
    }

    const handleUploadError = (error: any, uploadFile: any) => {
      ElMessage.error('文件上传失败')
    }

    const beforeUpload = (file: File) => {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt1M = file.size / 1024 / 1024 < 1

      if (!isJPG && !isPNG) {
        ElMessage.error('上传头像图片只能是 JPG 或 PNG 格式!')
      }
      if (!isLt1M) {
        ElMessage.error('上传头像图片大小不能超过 1MB!')
      }
      return (isJPG || isPNG) && isLt1M
    }

    const handleDownload = async (file: any) => {
      try {
        const response = await axios.get(`/api/download/${file.name}`)
        window.open(response.data.downloadUrl, '_blank')
      } catch (error) {
        ElMessage.error('获取下载链接失败')
      }
    }

    const handleDelete = async (file: any) => {
      try {
        await axios.delete(`/api/delete/${file.name}`)
        ElMessage.success('文件删除成功')
        fetchFileList()
      } catch (error) {
        ElMessage.error('文件删除失败')
      }
    }

    onMounted(() => {
      fetchFileList()
    })

    return {
      fileList,
      loading,
      handleUploadSuccess,
      handleUploadError,
      beforeUpload,
      handleDownload,
      handleDelete
    }
  }
})
</script>
