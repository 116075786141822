import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full items-start p-4" }
const _hoisted_2 = { class: "w-full flex justify-start border border-gray-300 rounded-md p-4 mb-4" }
const _hoisted_3 = { class: "w-full flex justify-start border border-gray-300 rounded-md p-4" }
const _hoisted_4 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "w-full flex justify-start items-center mb-4" }, [
      _createElementVNode("h2", { class: "text-xl font-bold" }, "DynamoDB 项目管理")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_form, {
        inline: true,
        model: _ctx.newItem,
        onSubmit: _withModifiers(_ctx.createItem, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "ID" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.newItem.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newItem.id) = $event)),
                placeholder: "输入ID"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "名称" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.newItem.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newItem.name) = $event)),
                placeholder: "输入名称"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "描述" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.newItem.actived,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newItem.actived) = $event)),
                placeholder: "输入描述"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _ctx.createItem
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("创建项目")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "onSubmit"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives((_openBlock(), _createBlock(_component_el_table, { data: _ctx.itemList }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "id",
            label: "ID",
            class: "bg-blue-100"
          }),
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: "名称",
            class: "bg-blue-100"
          }),
          _createVNode(_component_el_table_column, {
            prop: "actived",
            label: "描述",
            class: "bg-blue-100"
          }),
          _createVNode(_component_el_table_column, {
            label: "操作",
            class: "bg-blue-100"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_button, {
                size: "small",
                onClick: ($event: any) => (_ctx.getItem(scope.row.id))
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("查看")
                ])),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_el_button, {
                size: "small",
                type: "primary",
                onClick: ($event: any) => (_ctx.showUpdateDialog(scope.row))
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("更新")
                ])),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_el_button, {
                size: "small",
                type: "danger",
                onClick: ($event: any) => (_ctx.deleteItem(scope.row.id))
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("删除")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _ctx.loading]
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.updateDialogVisible,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.updateDialogVisible) = $event)),
      title: "更新项目"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_el_button, {
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateDialogVisible = false))
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createTextVNode("取消")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.updateItemConfirm
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode("确认")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: _ctx.updateItem,
          "label-width": "auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "ID" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.updateItem.id,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.updateItem.id) = $event)),
                  disabled: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "名称" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.updateItem.name,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.updateItem.name) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "描述" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.updateItem.actived,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.updateItem.actived) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}