<template>
    <div class="flex w-full justify-center items-center h-screen">
        <div class="container w-[28rem] bg-[#f8f4f3] p-8 rounded-md shadow-md">

            <a href="#" class="flex items-center pb-4 border-b border-b-gray-800 mb-4">
                <!-- タイトル -->
                <h2 class="font-bold text-xl">
                    百度 云函数 <span class="bg-[#f84525] text-white px-2 rounded-md">EXPRESS</span></h2>
            </a>

            <el-form :model="form" label-width="auto">

                <el-form-item label="メールアドレス:">
                    <el-input v-model="form.email" placeholder="メールアドレス" />
                </el-form-item>

                <el-form-item label="パスワード:">
                    <el-input show-password v-model="form.password" placeholder="パスワード" />
                </el-form-item>

                <div class="mb-6">
                    <p v-if="errorMessage" class="text-red-500  text-xs">{{ errorMessage }}</p>
                </div>
                <div class="flex justify-center">
                    <el-button type="primary" class="w-full" @click="onSubmit">ログイン</el-button>
                </div>
            </el-form>

        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';

export default defineComponent({
    name: 'LoginComponent',
    setup() {
        const form = ref({
            email: '',
            password: '',
        })
        const errorMessage = ref('');
        const router = useRouter();

        const onSubmit = async () => {
            try {
                if (form.value.email === '' || form.value.password === '') {
                    throw new Error('メールアドレスとパスワードを入力してください。');
                }

                if (form.value.email === 'huming@exp-corp.com' && form.value.password === '123456') {
                    localStorage.setItem('email', form.value.email);
                    router.push('/HelloWorld');
                    ElMessage.info(`ログインしました！`);
                } else {
                    throw new Error('ユーザー名またはパスワードが間違っています。');
                }

                // 异常処理
                // authService.handlePostLoginTasks(user_id);
            } catch (error) {
                errorMessage.value = 'ユーザー名またはパスワードが間違っています。';
            }
        };

        return {
            form,
            errorMessage,
            onSubmit
        };
    }
});
</script>
