import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AboutView from '../views/AboutView.vue'
import UploadView from '../views/UploadView.vue'
import DynamoDBView from '../views/DynamoDBView.vue'
import LoginComponent from '../views/Login.vue'
import ServerError from '../views/500.vue'
import NotFound from '../views/404.vue'
import BaseLayout from '../views/Layout.vue'
import HelloWorld from '../views/HelloWorld.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/',
    name: 'home',
    component: BaseLayout,
    children: [
        {
            path: 'HelloWorld',
            name: 'HelloWorld',
            component: HelloWorld
        },
        {
            path: 'About',
            name: 'About',
            component: AboutView,
        },
        {
            path: 'Upload',
            name: 'Upload',
            component: UploadView
        },
        {
            path: 'DynamoDB',
            name: 'DynamoDB',
            component: DynamoDBView
        }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginComponent
  },
  {
    path: '/500',
    name: '500',
    component: ServerError
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
