import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex w-full justify-center items-center h-screen" }
const _hoisted_2 = { class: "container w-[28rem] bg-[#f8f4f3] p-8 rounded-md shadow-md" }
const _hoisted_3 = { class: "mb-6" }
const _hoisted_4 = {
  key: 0,
  class: "text-red-500 text-xs"
}
const _hoisted_5 = { class: "flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("a", {
        href: "#",
        class: "flex items-center pb-4 border-b border-b-gray-800 mb-4"
      }, [
        _createElementVNode("h2", { class: "font-bold text-xl" }, [
          _createTextVNode(" 百度 云函数 "),
          _createElementVNode("span", { class: "bg-[#f84525] text-white px-2 rounded-md" }, "EXPRESS")
        ])
      ], -1)),
      _createVNode(_component_el_form, {
        model: _ctx.form,
        "label-width": "auto"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "メールアドレス:" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.form.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.email) = $event)),
                placeholder: "メールアドレス"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "パスワード:" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                "show-password": "",
                modelValue: _ctx.form.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password) = $event)),
                placeholder: "パスワード"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.errorMessage)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_button, {
              type: "primary",
              class: "w-full",
              onClick: _ctx.onSubmit
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("ログイン")
              ])),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ]))
}