import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full justify-center items-center h-screen" }
const _hoisted_2 = { class: "container w-[28rem] bg-[#f8f4f3] p-8 rounded-md shadow-md" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "mt-10 flex justify-center" }
const _hoisted_5 = {
  href: "/login",
  class: "w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("a", {
        href: "#",
        class: "flex items-center pb-4 border-b border-b-gray-800 mb-4"
      }, [
        _createElementVNode("h2", { class: "font-bold text-2xl" }, [
          _createTextVNode(" 百度 云函数 "),
          _createElementVNode("span", { class: "bg-[#f84525] text-white px-2 rounded-md" }, "EXPRESS")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-base font-semibold text-gray-600" }, "Server error!", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" }, "500", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("a", _hoisted_5, [
            _createVNode(_component_el_button, {
              type: "primary",
              class: "w-full"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("back home")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}