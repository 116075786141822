<template>
    <div class="flex flex-row w-full h-16 items-center justify-between p-0  border-b border-b-gray-800 shadow-md">
        <div class="flex items-center p-0">
            <a href="#" class="flex items-center p-4">
                <!-- タイトル -->
                <h2 class="font-bold text-xl">
                    百度 云函数 <span class="bg-[#f84525] text-white px-2 rounded-md">EXPRESS</span></h2>
            </a>
        </div>
        <div class="flex items-center justify-end px-4">
            <el-icon class="cursor-pointer m-4" @click="toggleCollapse">
                <TurnOff v-if="isCollapse" />
                <Open v-else />
            </el-icon>
            <el-icon class="cursor-pointer m-4" @click="logout">
                <SwitchButton />
            </el-icon>
        </div>
    </div>
    <div class="flex flex-row w-full min-h-[calc(100vh-64px)] p-0">
        <!-- メニュー -->
        <el-menu default-active="1" class="el-menu-vertical-demo shadow-md" :collapse="isCollapse" @open="handleOpen"
            @close="handleClose">
            <el-menu-item index="1" @click="router.push('/HelloWorld')">
                <el-icon><icon-menu /></el-icon>
                <template #title>HelloWorld</template>
            </el-menu-item>

            <el-menu-item index="2" @click="router.push('/Upload')">
                <el-icon>
                    <List />
                </el-icon>
                <template #title>Upload</template>
            </el-menu-item>

            <el-menu-item index="4" @click="router.push('/DynamoDB')">
                <el-icon>
                    <Bell />
                </el-icon>
                <template #title>DynamoDB</template>
            </el-menu-item>

            <el-menu-item index="5" @click="router.push('/About')">
                <el-icon>
                    <Avatar />
                </el-icon>
                <template #title>About</template>
            </el-menu-item>
        </el-menu>
        <!-- コンテンツ -->
        <div class="flex-1 bg-gray-100"
            :style="{ height: 'calc(100vh - 64px)', width: isCollapse ? 'calc(100% - 80px)' : 'calc(100% - 240px)' }">
            <router-view />
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
// import eventBus from '@/components/common/Event-bus';
import {
    SwitchButton,
    TurnOff,
    Open,
    Menu as IconMenu,
    Bell,
    List,
    Avatar,
} from '@element-plus/icons-vue'
import { Action, ElMessage, ElMessageBox } from 'element-plus';
import router from '../router';


export default defineComponent({
    name: 'BaseLayout',
    components: {
        IconMenu,
        List,
        SwitchButton,
        TurnOff,
        Open,
        Bell,
        Avatar,
    },
    setup() {
        const isCollapse = ref(false);


        const updateCollapse = () => {
            isCollapse.value = window.innerWidth < 768; // 根据屏幕宽度设置缩进
        }

        onMounted(() => {
            // console.log('onMounted');
            updateCollapse(); // 初始化时检查宽度
            window.addEventListener('resize', updateCollapse); // 监听窗口大小变化
        });

        onBeforeUnmount(() => {
            // console.log('onBeforeUnmount');
            window.removeEventListener('resize', updateCollapse); // 移除事件监听器
        });

        const handleOpen = (key: string, keyPath: string[]) => {
            console.log(key, keyPath)
        }
        const handleClose = (key: string, keyPath: string[]) => {
            console.log(key, keyPath)
        }

        const toggleCollapse = () => {
            isCollapse.value = !isCollapse.value;
        }


        /**
         * ログアウト
         */
        const logout = () => {
            console.log('logout');
            ElMessageBox.confirm('ログアウトしますか？', '確認', {
                confirmButtonText: 'OK',
                callback: (action: Action) => {

                    if (action === 'confirm') {
                        localStorage.removeItem('email');
                        router.push('/login');
                        // eventBus.emit('userLoggedOut'); // 触发退出事件
                        ElMessage.info(`ログアウトしました！`)

                    }
                }
            })
        }




        return {
            router,
            handleOpen,
            handleClose,
            toggleCollapse,
            isCollapse,
            logout,
        }
    },
});
</script>
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 240px;
}
</style>