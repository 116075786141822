import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full items-start p-4" }
const _hoisted_2 = { class: "w-full flex justify-start border border-gray-300 rounded-md p-4 mb-4" }
const _hoisted_3 = { class: "w-full flex justify-start border border-gray-300 rounded-md p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "w-full flex justify-start items-center mb-4" }, [
      _createElementVNode("h2", { class: "text-xl font-bold" }, "文件上传和管理")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_upload, {
        action: "/api/upload",
        "on-success": _ctx.handleUploadSuccess,
        "on-error": _ctx.handleUploadError,
        "before-upload": _ctx.beforeUpload,
        "show-file-list": false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, { type: "primary" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("点击上传")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["on-success", "on-error", "before-upload"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _ctx.fileList,
        class: "w-full bg-transparent"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: "文件名"
          }),
          _createVNode(_component_el_table_column, {
            prop: "lastModified",
            label: "最后修改时间"
          }),
          _createVNode(_component_el_table_column, {
            prop: "size",
            label: "大小(字节)"
          }),
          _createVNode(_component_el_table_column, { label: "操作" }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_button, {
                size: "small",
                onClick: ($event: any) => (_ctx.handleDownload(scope.row))
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("下载")
                ])),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_el_button, {
                size: "small",
                type: "danger",
                onClick: ($event: any) => (_ctx.handleDelete(scope.row))
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("删除")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _ctx.loading]
      ])
    ])
  ]))
}