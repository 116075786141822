<template>
    <div class="flex w-full justify-center items-center h-screen">
        <div class="container w-[28rem] bg-[#f8f4f3] p-8 rounded-md shadow-md">

            <a href="#" class="flex items-center pb-4 border-b border-b-gray-800 mb-4">
                <!-- タイトル -->
                <h2 class="font-bold text-2xl">
                    百度 云函数 <span class="bg-[#f84525] text-white px-2 rounded-md">EXPRESS</span></h2>
            </a>
            <div class="text-center">
                <p class="text-base font-semibold text-gray-600">Page not found!</p>
                <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">404</h1>
                <div class="mt-10 flex justify-center">
                    <a href="/login" class="w-full">
                        <el-button type="primary" class="w-full">back home</el-button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NotFound'
});
</script>

<style scoped>
h1 {
    color: red;
}
</style>