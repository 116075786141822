import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import './assets/styles/tailwind.css';
import 'element-plus/dist/index.css'


const app = createApp(App);



app.use(router);
app.use(ElementPlus);
// app.config.globalProperties.$messaging = messaging
app.mount('#app');