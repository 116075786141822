import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row w-full h-16 items-center justify-between p-0 border-b border-b-gray-800 shadow-md" }
const _hoisted_2 = { class: "flex items-center justify-end px-4" }
const _hoisted_3 = { class: "flex flex-row w-full min-h-[calc(100vh-64px)] p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TurnOff = _resolveComponent("TurnOff")!
  const _component_Open = _resolveComponent("Open")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_SwitchButton = _resolveComponent("SwitchButton")!
  const _component_icon_menu = _resolveComponent("icon-menu")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_List = _resolveComponent("List")!
  const _component_Bell = _resolveComponent("Bell")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex items-center p-0" }, [
        _createElementVNode("a", {
          href: "#",
          class: "flex items-center p-4"
        }, [
          _createElementVNode("h2", { class: "font-bold text-xl" }, [
            _createTextVNode(" 百度 云函数 "),
            _createElementVNode("span", { class: "bg-[#f84525] text-white px-2 rounded-md" }, "EXPRESS")
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_icon, {
          class: "cursor-pointer m-4",
          onClick: _ctx.toggleCollapse
        }, {
          default: _withCtx(() => [
            (_ctx.isCollapse)
              ? (_openBlock(), _createBlock(_component_TurnOff, { key: 0 }))
              : (_openBlock(), _createBlock(_component_Open, { key: 1 }))
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_icon, {
          class: "cursor-pointer m-4",
          onClick: _ctx.logout
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SwitchButton)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_menu, {
        "default-active": "1",
        class: "el-menu-vertical-demo shadow-md",
        collapse: _ctx.isCollapse,
        onOpen: _ctx.handleOpen,
        onClose: _ctx.handleClose
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_menu_item, {
            index: "1",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push('/HelloWorld')))
          }, {
            title: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("HelloWorld")
            ])),
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_menu)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_menu_item, {
            index: "2",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.router.push('/Upload')))
          }, {
            title: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Upload")
            ])),
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_List)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_menu_item, {
            index: "4",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.router.push('/DynamoDB')))
          }, {
            title: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("DynamoDB")
            ])),
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Bell)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_menu_item, {
            index: "5",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.router.push('/About')))
          }, {
            title: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("About")
            ])),
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Avatar)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["collapse", "onOpen", "onClose"]),
      _createElementVNode("div", {
        class: "flex-1 bg-gray-100",
        style: _normalizeStyle({ height: 'calc(100vh - 64px)', width: _ctx.isCollapse ? 'calc(100% - 80px)' : 'calc(100% - 240px)' })
      }, [
        _createVNode(_component_router_view)
      ], 4)
    ])
  ], 64))
}