<template>
  <div class="flex flex-col w-full items-start p-4">
    <div class="w-full flex justify-start items-center mb-4">
      <h2 class="text-xl font-bold">DynamoDB 项目管理</h2>
    </div>

    <div class="w-full flex justify-start border border-gray-300 rounded-md p-4 mb-4">
      <!-- 创建项目表单 -->
      <el-form :inline="true" :model="newItem" @submit.prevent="createItem">
        <el-form-item label="ID">
          <el-input v-model="newItem.id" placeholder="输入ID"></el-input>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="newItem.name" placeholder="输入名称"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="newItem.actived" placeholder="输入描述"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="createItem">创建项目</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 项目列表 -->
    <div class="w-full flex justify-start border border-gray-300 rounded-md p-4">
      <el-table :data="itemList" v-loading="loading">
        <el-table-column prop="id" label="ID" class="bg-blue-100"></el-table-column>
        <el-table-column prop="name" label="名称" class="bg-blue-100"></el-table-column>
        <el-table-column prop="actived" label="描述" class="bg-blue-100"></el-table-column>
        <el-table-column label="操作" class="bg-blue-100">
          <template #default="scope">
            <el-button size="small" @click="getItem(scope.row.id)">查看</el-button>
            <el-button size="small" type="primary" @click="showUpdateDialog(scope.row)">更新</el-button>
            <el-button size="small" type="danger" @click="deleteItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 更新项目对话框 -->
    <el-dialog v-model="updateDialogVisible" title="更新项目">
      <el-form :model="updateItem" label-width="auto">
        <el-form-item label="ID">
          <el-input v-model="updateItem.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="updateItem.name"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="updateItem.actived"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="updateDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="updateItemConfirm">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default defineComponent({
  name: 'DynamoDBView',
  setup() {
    const itemList = ref([]);
    const newItem = ref({ id: '', name: '', actived: '' });
    const updateItem = ref({ id: '', name: '', actived: '' });
    const updateDialogVisible = ref(false);
    const loading = ref(false);

    const fetchItemList = async () => {
      loading.value = true
      try {
        const response = await axios.get('/api/items')

        itemList.value = response.data
      } catch (error) {
        ElMessage.error('获取项目列表失败')
      } finally {
        loading.value = false
      } 
    }

    const createItem = async () => {
      try {
        await axios.post('/api/items', newItem.value)
        ElMessage.success('项目创建成功')
        newItem.value = { id: '', name: '', actived: '' }
        fetchItemList()
      } catch (error) {
        ElMessage.error('创建项目失败')
      }
    }

    const getItem = async (id: string) => {
      try {
        const response = await axios.get(`/api/items/${id}`)
        ElMessage.success(`项目详情：${JSON.stringify(response.data)}`)
      } catch (error) {
        ElMessage.error('获取项目详情失败')
      }
    }

    const showUpdateDialog = (item: any) => {
      updateItem.value = { ...item }
      updateDialogVisible.value = true
    }

    const updateItemConfirm = async () => {
      try {
        await axios.put(`/api/items/${updateItem.value.id}`, updateItem.value)
        ElMessage.success('项目更新成功')
        updateDialogVisible.value = false
        fetchItemList()
      } catch (error) {
        ElMessage.error('更新项目失败')
      }
    }

    const deleteItem = async (id: string) => {
      try {
        await axios.delete(`/api/items/${id}`)
        ElMessage.success('项目删除成功')
        fetchItemList()
      } catch (error) {
        ElMessage.error('删除项目失败')
      }
    }

    onMounted(() => {
      fetchItemList()
    })

    return {
      itemList,
      loading,
      newItem,
      updateItem,
      updateDialogVisible,
      createItem,
      getItem,
      showUpdateDialog,
      updateItemConfirm,
      deleteItem
    }
  }
})
</script>
