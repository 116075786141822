<template>
  <el-config-provider :z-index="zIndex">
    <router-view />
  </el-config-provider>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  // 颜色主题更换说明
  // https://element-plus.org/zh-CN/guide/theming.html
  mounted() {
    console.log('APP mounted');
  },
  data() {
    return {
      size: 'default',
      zIndex: 30,
    }
  }
});
</script>
